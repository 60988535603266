import { locale } from 'locale'
import moment from 'moment'
import { formatDate } from 'utils/dates'
import {
  Badge,
  FilledButton,
  Loader,
  NotificationContainer,
  Table,
  Wrapper,
  useNotification,
} from 'components'
import { useTrainings } from 'hooks/useTrainings'
import { Card } from 'components/Card'

export const Certificates = () => {
  const { notifications } = useNotification()

  const today = moment().format('YYYY-MM-DD')
  const currentHour = moment().subtract(2, 'hour').format('HH:mm')

  const { trainings, loading } = useTrainings({
    params: {
      status: 'published',
      tutorial: false,
      date: today,
      hour: currentHour,
      type: 'certificates',
    },
  })

  if (loading) {
    return <Loader />
  }

  return (
    <div className="w-full flex-1 py-12">
      <Wrapper>
        <NotificationContainer notifications={notifications} />
        <h2 className="uppercase text-sm font-medium text-darkBlue">
          Formação
        </h2>
        <h1 className="pb-10 text-xxxl font-medium text-brand">
          Certificações
        </h1>
        <Card>
          <Table
          newLook={true}
            title="Mapa de certificados"
            columns={[
              {
                label: locale.title,
                property: 'name',
                sortable: false,
                format: item => item.name,
              },
              {
                label: locale.teacher,
                property: 'teacher',
                sortable: false,
                format: item => item.teacher,
              },
              {
                label: locale.date,
                property: 'date',
                sortable: false,
                format: item => formatDate(item.date).dashed,
              },
              {
                label: 'Certificado',
                property: 'presences.status',
                sortable: false,
                format: item => {
                  return item.presence?.[0] &&
                    item.presences?.[0].status === 'attended' ? (
                    item.presences[0].certificate_url ? (
                      <FilledButton
                        label="Download"
                        url={item.presences[0].certificate_url}
                      />
                    ) : (
                      <Badge status="scheduled" label="Ainda não disponível" />
                    )
                  ) : (
                    <Badge status="rejected" label="Não participou" />
                  )
                },
              },
            ]}
            data={trainings}
          />
        </Card>
      </Wrapper>
    </div>
  )
}
