import {
  Loader,
  NotificationContainer,
  Wrapper,
  useNotification,
} from 'components'
import { locale } from 'locale'
import { ContentEditor } from 'components'
import { BodyItem } from 'types/editor'
import { getContent } from 'services/content'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { Card } from 'components/Card'
import { Cog } from 'components/Icons/cog'

export const Process = () => {
  const { slug } = useParams() as { slug: string }

  const { notifications, notify } = useNotification()

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['getProcess', { slug }],
    queryFn: () =>
      getContent({
        params: {
          type: 'process',
          slug,
        },
      }),
  })

  if (isError && error) {
    notify()
  }

  if (isLoading) {
    return <Loader />
  }

  const product = data?.content?.[0]
  const attachments = product?.attachments
  const productBody = product?.body.length > 0 ? JSON.parse(product.body) : []
  const columns: Array<Array<BodyItem>> = []
  let index = 0
  productBody.forEach((item: BodyItem) => {
    if (item.type === 'delimiter') {
      index++
      columns[index] = []
      columns[index].push(item)
      index++
    }
    if (item.type !== 'delimiter') {
      if (!columns[index]) {
        columns[index] = []
      }
      columns[index].push(item)
    }
  })

  return (
    <div className="w-full flex-1 py-12">
      <Wrapper>
        <Card extraClasses="p-8">
          <NotificationContainer notifications={notifications} />
          <div className="sm:flex-auto pt-8 mb-6 sm:flex sm:items-center">
            <Cog></Cog>
            <h1 className="px-3 sm:px-3 font-roboto text-xl font-medium leading-6 text-black uppercase">
              {`${locale.process.plural} ${locale.administrative}`}
            </h1>
          </div>
          {product && (
            <>
              <h1 className="pb-10 font-roboto text-xl font-medium text-brand">
                {product.title}
              </h1>
              <div className="grid grid-cols-[1fr_1px_1fr] grid-rows-[auto_1fr] gap-x-12 items-start justify-between">
                {columns.map((column, index) => {
                  return (
                    <div key={`editor_col_${index}`} className="h-full">
                      {column.map((item, index) => {
                        return (
                          <ContentEditor
                            key={`editor_${index}`}
                            attachments={attachments}
                            index={index}
                            item={item}
                            notify={notify}
                          />
                        )
                      })}
                    </div>
                  )
                })}
              </div>
            </>
          )}
        </Card>
      </Wrapper>
    </div>
  )
}
