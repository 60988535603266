import { useContext } from 'react'

import { AuthContext } from '../../contexts/AuthContext'
import { AppContext } from 'contexts/AppContext'

import { LocaleType, locale } from 'locale'
import { Loader, Navbar } from 'components'
import {
  MenuHome,
  MenuProposals,
  MenuProducts,
  MenuCommunications,
  MenuProcesses,
  MenuTrainings,
} from 'components/Icons'
import { SubMenuType } from 'types/navbar'
import { MenuClaims } from 'components/Icons/menuClaims'

export const HeaderBroker = () => {
  const { logout, currentUser } = useContext(AuthContext)
  const { products, process } = useContext(AppContext)
  let paymentsSubMenu: SubMenuType = {}
  let productSubMenu: SubMenuType = {}
  let simulatorsSubMenu: SubMenuType = {}

  if (process) {
    paymentsSubMenu = {
      type: 'pagamentos',
      label: 'Toda a informação sobre a regularização dos seguros.',
      columns: [
        {
          label: locale.process.plural + ' ' + locale.administrative,
          items: process.content
            .filter(process => process.slug === 'pagamentos')
            .map(process => ({ label: process.title, slug: process.slug })),
        },
      ],
    }

    const groupBy = (input: any, key: string) => {
      return input.reduce((acc: any, currentValue: any) => {
        let groupKey = currentValue[key]
        if (!acc[groupKey]) {
          acc[groupKey] = []
        }
        acc[groupKey].push(currentValue)
        return acc
      }, {})
    }

    const productColumns = groupBy(products, 'type')
    const productsSubmenu = Object.entries(productColumns).map(
      ([key, value]: [key: string, value: any]) => {
        return {
          label: locale[key as LocaleType] as string,
          items: value.map((product: any) => {
            return {
              label: product.short_title ?? product.title,
              slug: product.slug,
            }
          }),
        }
      },
    )

    productSubMenu = {
      type: 'produto',
      label: 'Conheça a nossa oferta de produtos',
      columns: productsSubmenu,
    }

    const productsWithSimulators = products.filter(
      (product: any) => product.has_simulator,
    )

    const simulatorsColumns = groupBy(productsWithSimulators, 'type')
    const simulatorsSubmenu = Object.entries(simulatorsColumns).map(
      ([key, value]: [key: string, value: any]) => {
        return {
          label: locale[key as LocaleType] as string,
          items: value.map((product: any) => {
            return {
              label: product.short_title || product.title,
              slug: product.slug,
            }
          }),
        }
      },
    )

    simulatorsSubMenu = {
      type: 'simuladores',
      label:
        'Simule qualquer um dos nossos produtos em apenas 3 simples passos.',
      columns: simulatorsSubmenu,
    }
  }

  if (!paymentsSubMenu && !productSubMenu && !simulatorsSubMenu) {
    return <Loader />
  }

  return (
    <Navbar
      activeItem={window && window.location.pathname}
      topBar={{
        items: [
          {
            label: currentUser?.broker?.name ?? 'Broker',
            url: currentUser?.role ? '/profile-broker' : '#',
          },
          {
            label: currentUser?.forename ?? '',
            url: '/profile',
          },
        ],

        onLogout: logout,
      }}
      bottomBar={[
        {
          label: 'Produtos',
          url: '#',
          hasSubMenu: true,
          submenu: productSubMenu,
          icon: MenuProducts,
        },
        {
          label: 'Simuladores',
          url: '#',
          hasSubMenu: true,
          submenu: simulatorsSubMenu,
          icon: MenuProposals,
        },
        {
          label: 'Consultas',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: '',
            label: 'Toda a informação.',
            columns: [
              {
                label: 'Consultas',
                items: [
                  {
                    label: 'Apólices',
                    slug: 'policies',
                  },
                  {
                    label: 'Cotações',
                    slug: 'quotations',
                  },
                  {
                    label: 'Recibos',
                    slug: 'invoices',
                  },
                ],
              },
            ],
          },
          icon: MenuHome,
        },
        {
          label: 'Informações',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: 'informations',
            label: 'Toda a informação sobre a regularização dos seguros.',
            columns: [
              {
                label: 'Pagamentos',
                items: [
                  {
                    label: 'Pagamentos à Innovarisk',
                    slug: 'pagamentos',
                  },
                  {
                    label: 'Documentos',
                    slug: 'documentos',
                  },
                ],
              },
            ],
          },
          icon: MenuProcesses,
        },
        {
          label: 'Comunicações',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: 'communications',
            label: 'Toda a informação.',
            columns: [
              {
                label: 'Comunicações',
                items: [
                  {
                    label: 'Campanhas',
                    slug: 'campaign',
                  },
                  {
                    label: 'Newsletters',
                    slug: 'newsletter',
                  },
                  {
                    label: 'Flash',
                    slug: 'information',
                  },
                ],
              },
            ],
          },
          icon: MenuCommunications,
        },
        {
          label: 'Sinistros',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: 'sinistros',
            label: 'Toda a informação sobre sinistros.',
            columns: [
              {
                label: 'Participação de sinistro',
                items: [
                  {
                    label: 'Participação de sinistro',
                    slug: 'sinistros',
                  },
                ],
              },
            ],
          },
          icon: MenuProcesses,
        },
        {
          label: 'Formações',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: '',
            label: 'Todas as formações',
            columns: [
              {
                label: 'Formações',
                items: [
                  {
                    label: 'Em curso ou futuras',
                    slug: 'trainings',
                  },
                  {
                    label: 'Passadas',
                    slug: 'past-trainings',
                  },
                  {
                    label: 'Tutoriais',
                    slug: 'tutorials',
                  },
                  {
                    label: 'Certificados',
                    slug: 'certificates',
                  },
                ],
              },
            ],
          },
          icon: MenuTrainings,
        },
      ]}
      sideBar={[
        {
          label: 'Produtos',
          url: '#',
          hasSubMenu: true,
          submenu: productSubMenu,
          icon: MenuProducts,
        },
        {
          label: 'Simuladores',
          url: '#',
          hasSubMenu: true,
          submenu: simulatorsSubMenu,
          icon: MenuProposals,
        },
        {
          label: 'Consultas',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: '',
            label: 'Toda a informação.',
            columns: [
              {
                label: 'Consultas',
                items: [
                  {
                    label: 'Apólices',
                    slug: 'policies',
                  },
                  {
                    label: 'Cotações',
                    slug: 'quotations',
                  },
                  {
                    label: 'Recibos',
                    slug: 'invoices',
                  },
                ],
              },
            ],
          },
          icon: MenuHome,
        },
        {
          label: 'Informações',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: 'informations',
            label: 'Toda a informação sobre a regularização dos seguros.',
            columns: [
              {
                label: 'Pagamentos',
                items: [
                  {
                    label: 'Pagamentos à Innovarisk',
                    slug: 'pagamentos',
                  },
                  {
                    label: 'Documentos',
                    slug: 'documentos',
                  },
                ],
              },
            ],
          },
          icon: MenuProcesses,
        },
        {
          label: 'Comunicações',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: 'communications',
            label: 'Toda a informação.',
            columns: [
              {
                label: 'Comunicações',
                items: [
                  {
                    label: 'Campanhas',
                    slug: 'campaign',
                  },
                  {
                    label: 'Newsletters',
                    slug: 'newsletter',
                  },
                  {
                    label: 'Flash',
                    slug: 'information',
                  },
                ],
              },
            ],
          },
          icon: MenuCommunications,
        },
        {
          label: 'Sinistros',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: 'sinistros',
            label: 'Toda a informação sobre sinistros.',
            columns: [
              {
                label: 'Participação de sinistro',
                items: [
                  {
                    label: 'Participação de sinistro',
                    slug: 'sinistros',
                  },
                ],
              },
            ],
          },
          icon: MenuClaims,
        },
        {
          label: 'Formações',
          url: '#',
          hasSubMenu: true,
          submenu: {
            type: '',
            label: 'Todas as formações',
            columns: [
              {
                label: 'Formações',
                items: [
                  {
                    label: 'Em curso ou futuras',
                    slug: 'trainings',
                  },
                  {
                    label: 'Passadas',
                    slug: 'past-trainings',
                  },
                  {
                    label: 'Tutoriais',
                    slug: 'tutorials',
                  },
                  {
                    label: 'Certificados',
                    slug: 'certificates',
                  },
                ],
              },
            ],
          },
          icon: MenuTrainings,
        },
      ]}
    />
  )
}
