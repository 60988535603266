import { FilledButton, WrapperSmall, Wrapper } from 'components'
import { Download } from 'components/Icons'
import { Cog } from 'components/Icons/cog'
import { locale } from 'locale'
import { Link } from 'react-router-dom'

export const Newsletter = ({
  content,
  image,
  newLook,
}: {
  content: any
  image: any
  newLook: boolean
}) => {
  const { title, body } = content
  const formatedBody = JSON.parse(body)

  if (formatedBody.length === 0) {
    return null
  }

  if (newLook) {
    return (
      <div className="bg-cloudyBlue2/20 h-full">
        <Wrapper>
          <div
            className="h-auto bg-right-top bg-[length:200px_auto] md:bg-[0%_50%] md:bg-[length:auto_50%]  bg-no-repeat"
            style={{ backgroundImage: `url(${image})` }}
          >
            <div className="py-14">
                <div className="sm:flex-auto px-2 mb-6 sm:flex sm:items-center">
                  <Cog></Cog>
                  <h2 className="px-3 sm:px-3 font-mont text-2xl font-roboto leading-6 text-black uppercase">
                    {title}
                  </h2>
                </div>
                <div className="pb-8">
                  {formatedBody.map((item: any, index: number) => {
                    switch (item.type) {
                      case 'header':
                        return (
                          <h3
                            key={`campaign${index}`}
                            className="text-black font-mont text-lg font-medium pb-4"
                          >
                            {item.data.text}
                          </h3>
                        )

                      case 'fileList':
                        return (
                          <div
                            key={`campaign${index}`}
                            className="flex items-center justify-between w-full"
                          >
                            <span className="text-black font-lato text-base leading-7">
                              {item.data.title}
                            </span>
                            <Link
                              to={item.data.items[0].file}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="flex items-center justify-start text-brand">
                                <div className="w-4 h-4">
                                  <Download />
                                </div>
                                <span className="font-mont text-brand text-sm uppercase px-4">
                                  {item.data.items[0].label}
                                </span>
                              </div>
                            </Link>
                          </div>
                        )

                      default:
                      case 'paragraph':
                        return (
                          <p
                            key={`campaign${index}`}
                            className="font-lato text-base text-darkBlue leading-7 pb-1"
                            dangerouslySetInnerHTML={{ __html: item.data.text }}
                          />
                        )
                    }
                  })}
                </div>
                <FilledButton
                  className="inline-block text-sm px-8"
                  url="/communications/newsletter"
                  label={locale.seeAllNewsletters}
                />
            </div>
          </div>
        </Wrapper>
      </div>
    )
  }

  return (
    <div className="bg-cloudyBlue2/20">
      <Wrapper>
        <div
          className="h-auto bg-right-top bg-[length:200px_auto] md:bg-[0%_50%] md:bg-[length:auto_50%]  bg-no-repeat"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="py-14">
            <WrapperSmall>
              <h2 className="font-mont text-brand text-xxxl pb-10 md:pb-6">
                {title}
              </h2>
              <div className="pb-8">
                {formatedBody.map((item: any, index: number) => {
                  switch (item.type) {
                    case 'header':
                      return (
                        <h3
                          key={`campaign${index}`}
                          className="text-black font-mont text-lg font-medium pb-4"
                        >
                          {item.data.text}
                        </h3>
                      )

                    case 'fileList':
                      return (
                        <div
                          key={`campaign${index}`}
                          className="flex items-center justify-between w-full"
                        >
                          <span className="text-black font-lato text-base leading-7">
                            {item.data.title}
                          </span>
                          <Link
                            to={item.data.items[0].file}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="flex items-center justify-start text-brand">
                              <div className="w-4 h-4">
                                <Download />
                              </div>
                              <span className="font-mont text-brand text-sm uppercase px-4">
                                {item.data.items[0].label}
                              </span>
                            </div>
                          </Link>
                        </div>
                      )

                    default:
                    case 'paragraph':
                      return (
                        <p
                          key={`campaign${index}`}
                          className="font-lato text-base text-darkBlue leading-7 pb-1"
                          dangerouslySetInnerHTML={{ __html: item.data.text }}
                        />
                      )
                  }
                })}
              </div>
              <FilledButton
                className="inline-block text-sm px-8"
                url="/communications/newsletter"
                label={locale.seeAllNewsletters}
              />
            </WrapperSmall>
          </div>
        </div>
      </Wrapper>
    </div>
  )
}
