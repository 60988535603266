import { List } from 'components'
import { Download } from 'components/Icons'
import { Cog } from 'components/Icons/cog'
import { Link } from 'react-router-dom'

export const Information = ({
  content,
  image,
}: {
  content: any
  image: any
}) => {
  const { title, body } = content
  const formatedBody = JSON.parse(body)

  if (formatedBody.length === 0) {
    return null
  }

  return (
    <div
      className="h-full bg-[length:200px_auto] lg:bg-[length:400px_auto] bg-right-top md:bg-right-bottom bg-no-repeat bg-lightpink"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="py-12 px-8">
        <div className="sm:flex-auto px-2 mb-6 sm:flex sm:items-center">
          <Cog></Cog>
          <h2 className="px-3 sm:px-3 font-mont text-2xl font-roboto leading-6 text-black uppercase">
            {title}
          </h2>
        </div>

        <div className="pb-8">
          {formatedBody.map((item: any, index: number) => {
            switch (item.type) {
              case 'header':
                return (
                  <h4
                    key={`information_header${index}`}
                    className="font-mont  text-darkBlue text-xl font-medium pt-2 pb-4"
                  >
                    {item.data.text}
                  </h4>
                )

              case 'fileList':
                return (
                  <div
                    key={`information_filelist${index}`}
                    className="flex items-center justify-between w-full"
                  >
                    <p className="font-lato text-darkBlue text-md leading[1.75]">
                      {item.data.title}
                    </p>
                    <Link
                      type="link"
                      to={item.data.items[0].file}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="flex items-center text-brand">
                        <Download />
                        <p className="font-mont text-sm text-brand uppercase px-4">
                          {item.data.items[0].label}
                        </p>
                      </span>
                    </Link>
                  </div>
                )

              case 'list':
                return (
                  <List
                    key={`information_list${index}`}
                    id={`information_list${index}`}
                    type={item.data.style}
                    items={item.data.items}
                  />
                )

              default:
              case 'paragraph':
                return (
                  <p
                    key={`information-paragraph${index}`}
                    className="text-lato text-darkBlue text-md leading[1.75] pb-4"
                    dangerouslySetInnerHTML={{ __html: item.data.text }}
                  ></p>
                )
            }
          })}
        </div>
      </div>
    </div>
  )
}
