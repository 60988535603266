import { Link } from 'react-router-dom'
import { SubMenuType } from 'types/navbar'

type BottomSubMenuType = SubMenuType & {
  subMenuVisible: number[]
  index: number
  onClick: () => void
}

type SideSubMenuType = SubMenuType & {
  subMenuVisible: number[]
  index: number
  onClick: () => void
}

export const SubMenu = ({
  label,
  columns,
  type,
  subMenuVisible,
  index,
  onClick,
}: BottomSubMenuType) => {
  return (
    <div
      className={`${
        subMenuVisible[index] ? 'visible opacity-100' : 'hidden opacity-0'
      } relative lg:absolute w-full lg:top-full lg:left-0 z-[100] rounded-xl lg:rounded-none bg-lightGrey`}
    >
      <div className="overflow-y-auto lg:max-h-full w-full max-w-[1192px] px-9 m-auto">
        <div className="flex items-center justify-start flex-wrap pt-6 lg:pt-10 lg:pb-12">
          <div className="hidden lg:flex lg:w-[calc(41.6667%)] pr-4">
            <h3 className="overflow-hidden items-center justify-center text-xl font-light leading-8 font-mont text-grey text-left">
              {label}
            </h3>
          </div>
          <div className="w-full lg:w-[calc(58.3333%)] lg:pl-8 lg:border-l-4 lg:border-cloudyBlue">
            {
              <div
                className={`${
                  columns && columns.length === 3
                    ? 'lg:grid-cols-3'
                    : 'lg:grid-cols-2'
                } grid grid-cols-1 gap-x-8 items-start justify-center`}
              >
                {columns &&
                  Object.entries(columns).map(([key, value]) => {
                    return (
                      <div key={key}>
                        <h3
                          className={`inline-block w-auto pr-8 border-b-2 border-b-brand overflow-hidden font-lato text-sm font-medium text-brand uppercase ${
                            columns.length === 3 ? 'lg:h-10 ' : 'h-auto'
                          }`}
                        >
                          {value.label}
                        </h3>
                        <div className="pb-8 lg:py-4">
                          {value.items.map(item => {
                            return (
                              <Link
                                onClick={onClick}
                                className={`block pt-6 lg:pt-3 text-darkBlue font-mont font-medium text-sm hover:text-brand focus:text-brand`}
                                to={
                                  type
                                    ? '/' + type + '/' + item.slug
                                    : '/' + item.slug
                                }
                                key={item.slug}
                              >
                                {item.label}
                              </Link>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export const SideSubMenu = ({
  label,
  columns,
  type,
  subMenuVisible,
  index,
  onClick,
}: SideSubMenuType) => {
  return (
  <div className={`${
        subMenuVisible[index] ? 'visible opacity-100' : 'hidden opacity-0'
      } absolute w-fit left-full z-[100] rounded-xl lg:rounded-none bg-mainMenuRed`}>
        <div className="overflow-y-auto lg:max-h-full max-w-[1192px] m-auto">
        <div className="flex items-center justify-start flex-wrap">
          <div className="w-full lg:w-[calc(58.3333%)]">
            {
              <div
                className={`flex flex-col gap-x-8 items-start justify-center`}
              >
                {columns &&
                  Object.entries(columns).map(([key, value]) => {
                    return (
                      <div className="p-5" key={key}>
                        <h3
                          className={`inline-block w-auto pr-8 overflow-hidden font-roboto text-sm font-semibold text-white uppercase ${
                            columns.length === 3 ? 'lg:h-10 ' : 'h-auto'
                          }`}
                        >
                          {value.label}
                        </h3>
                        <div className="pb-4 lg:py-2">
                          {value.items.map(item => {
                            return (
                              <Link
                                onClick={onClick}
                                className={`block pt-6 lg:pt-3 text-white font-roboto font-light text-sm hover:text-opacity-50 focus:text-opacity-50`}
                                to={
                                  type
                                    ? '/' + type + '/' + item.slug
                                    : '/' + item.slug
                                }
                                key={item.slug}
                              >
                                {item.label}
                              </Link>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
              </div>
            }
          </div>
        </div>
      </div>
  </div>)

}
