import { useContext, useState } from 'react'
import { AuthContext } from 'contexts/AuthContext'
import { locale } from 'locale'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Badge, Filter, Table, Wrapper } from 'components'
import { useMutation, useQuery } from '@tanstack/react-query'
import { downloadQuotationsExcel, getQuotations } from 'services/policies'
import { AppContext } from 'contexts/AppContext'
import { PoliciesParams } from 'types/policies'
import { Link } from 'react-router-dom'
import { Card } from 'components/Card'

const validationSchema = Yup.object().shape({
  search: Yup.string(),
})

export const Cotations = () => {
  const { products } = useContext(AppContext)
  const { currentUser } = useContext(AuthContext)

  const brokerNo = currentUser?.broker?.broker_no
  const contactNo = currentUser?.contact_no

  const [queryVars, setQueryVars] = useState<PoliciesParams>({
    productCode: undefined,
    status: undefined,
    brokerNo,
    contactNo,
  })

  const { data, isLoading } = useQuery({
    queryKey: ['getCotations', queryVars],
    enabled: !!brokerNo && !!contactNo,
    retry: false,
    queryFn: () =>
      getQuotations({
        params: { ...queryVars, brokerNo, contactNo },
      }),
  })

  const formik = useFormik<{
    productCode?: string
    policyStatus?: string
  }>({
    initialValues: {
      productCode: undefined,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      const { productCode, policyStatus } = values
      setQueryVars({
        ...(productCode && { productCode }),
        ...(policyStatus && { status: policyStatus }),
        brokerNo,
        contactNo,
      })
    },
  })

  const { mutateAsync: handleExcelDownload, isPending: isPendingCsvDownload } =
    useMutation({
      mutationKey: ['downloadQuotationsExcel'],
      mutationFn: ({ queryVars }: { queryVars: PoliciesParams }) =>
        downloadQuotationsExcel({
          params: { ...queryVars, brokerNo, contactNo },
        }),
      onError: (error: Error) => console.error(error),
      onSuccess: async (response: any) => {
        if (response.status === 404) {
          throw new Error('Resource not found')
        }

        const blob = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        window.open(URL.createObjectURL(blob))
      },
    })

  // if (isLoading) {
  //   return <Loader />
  // }

  const filters = [
    {
      id: 'productCode',
      name: 'Produto',
      type: 'option',
      options: products.map((product: any) => {
        return { id: product.integration_code, name: product.title }
      }),
    },
    {
      id: 'policyStatus',
      name: 'Estado',
      type: 'option',
      options: [
        { name: 'Activo', id: 'Active' },
        { name: 'Cancelado', id: 'Cancelled' },
      ],
    },
    /* {
      id: 'userId',
      name: 'Utilizadores',
      type: 'option',
      options: [{ name: currentUser?.forename, id: currentUser?.id }],
    },
    {
      id: 'search',
      name: 'Pesquisa',
      type: 'search',
      options: [],
    }, */
  ]

  return (
    <div className="w-full flex-1 py-12">
      <Wrapper>
        <div className="lg:pt-8">
          <Filter
            isLoading={isPendingCsvDownload}
            filters={filters}
            formik={formik}
            actionLabel="Exportar para CSV"
            action={() => handleExcelDownload({ queryVars })}
          />

          <Card>
            <Table
              title="Cotações"
              newLook={true}
              width="lg:w-fit"
              columns={[
                {
                  label: locale.policiesTable.ref,
                  property: 'policy_no',
                  sortable: true,
                  format: item => item['Nº da Apólice'],
                },
                /* {
                  label: locale.policiesTable.user,
                  property: 'user_id',
                  sortable: true,
                  format: item => item.user?.forename,
                }, */
                {
                  label: locale.policiesTable.holder,
                  property: 'policy_holder',
                  sortable: true,
                  format: item => item['Tomador'],
                },
                {
                  label: locale.policiesTable.product,
                  property: 'product_title',
                  sortable: true,
                  format: item => item['Produto'] || 'Produto indisponível',
                },
                {
                  label: locale.policiesTable.date,
                  property: 'updated_at',
                  sortable: true,
                  format: item => item['Data da Renovação'],
                },
                {
                  label: locale.policiesTable.status,
                  property: 'policy_status',
                  sortable: true,
                  format: item => {
                    switch (item['Estado']) {
                      case 'Cancelled':
                        return <Badge label={item['Estado']} status="deleted" />
                      case 'Active':
                        return <Badge label={item['Estado']} status="active" />
                      case 'submitted':
                        return <Badge label={item['Estado']} status="submitted" />
                      case 'expired':
                        return <Badge label={item['Estado']} status="pending" />
                      case 'pending':
                      case 'draft':
                      default:
                        return <Badge label={item['Estado']} status="draft" />
                    }
                  },
                },
                {
                  label: locale.actions,
                  property: 'button',
                  sortable: false,
                  format: item => (
                    <Link
                      className="underline underline-offset-4 decoration-cloudyBlue hover:decoration-brand"
                      to={`/quotations/${item['Nº da Apólice']}`}
                    >
                      Detalhes
                    </Link>
                  ),
                },
              ]}
              data={data ?? []}
            />
          </Card>
        </div>

      </Wrapper>
    </div>
  )
}
