import { useContext, useEffect, useState } from 'react'

import { getDashboard } from 'services/dashboard'
import { AuthContext } from '../../contexts/AuthContext'
import { AppContext } from 'contexts/AppContext'

import { Campaign } from './campaign'
// import { Information } from './information';

import {
  NotificationContainer,
  useNotification,
  FilledButton,
  Modal,
  Loader,
} from 'components'
import { Newsletter } from './newsletter'
import { Information } from './information'
import { useQuery } from '@tanstack/react-query'
import { Card } from 'components/Card'
import { Cog } from 'components/Icons/cog'
import { Email2, Phone2 } from 'components/Icons'
// import { Doughnut, HorizontalBar, Pie } from 'react-chartjs-2'
import { Trainings } from './trainings'

const DashboardBroker = () => {
  const { currentUser } = useContext(AuthContext)
  const { settings } = useContext(AppContext)
  const { notifications, notify } = useNotification()
  const [modalVisibility, setModayVisibility] = useState(false)
  /* const today = moment().format('YYYY-MM-DD') */

  const { data, isLoading } = useQuery({
    queryKey: ['getDashboard'],
    queryFn: () => getDashboard(),
  })

  useEffect(() => {
    if (currentUser && (!currentUser?.birthdate || !currentUser?.id_card)) {
      setModayVisibility(true)
    }
  }, [currentUser])

  const closeModal = () => {
    setModayVisibility(false)
  }

  if (isLoading || !settings) {
    return <Loader />
  }

  /*TODO Revisit incomplete code*/
  const sideBarOptions = {
    indexAxis: 'y', // Make the bars horizontal
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          display: false, // Hide x-axis ticks/numbers
        },
      },
      y: {
        ticks: {
          display: false, // Hide y-axis ticks/labels
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
  };

  const sideBarData = {
    labels: ['', '', '', ''], // Empty labels
    datasets: [
      {
        label: 'Data',
        data: [50, 50, 50, 50], // Example data, all values are the same
        backgroundColor: 'black',
      },
    ],
  };


  /* eslint-disable @typescript-eslint/no-unused-vars */
  const campaignImage = settings.find(
    (item: any) => item.name === 'campaignImage',
  )?.value
  const newsletterImage = settings.find(
    (item: any) => item.name === 'newsletterImage',
  )?.value
  const infoImage = settings.find(
    (item: any) => item.name === 'infoImage',
  )?.value
  const trainingsImage = settings.find(
    (item: any) => item.name === 'trainingsImage',
  )?.value

  return (
    <div className="w-full grid grid-cols-3 gap-4 px-[18rem]">
      <NotificationContainer notifications={notifications} />

      {data.campaign && (
        <Card extraClasses="!h-full !w-full !max-h-[380px] col-span-2">
          <Campaign content={data.campaign} image={campaignImage} />
        </Card>
      )}

      {/* <Charts /> */}

      {data.trainings && (
        <Card extraClasses="!h-full !w-full !max-h-[380px]">
          <Trainings
            newLook={true}
            content={data.trainings}
            image={trainingsImage}
            userID={currentUser?.id!}
          />
        </Card>

      )}

      {data.newsletter && (
        <Card extraClasses="!h-full !w-full !max-h-[380px] col-span-1">
          <Newsletter newLook={true} content={data.newsletter} image={newsletterImage} />
        </Card>
      )}

      {data.information && (
        <Card extraClasses="!h-full !w-full !max-h-[380px] col-span-2">
          <Information content={data.information} image={infoImage} />
        </Card>
      )}

      <Card extraClasses="px-8 py-14 !bg-black !h-full !w-full !max-h-[380px] col-span-1">
        <div className="mb-10">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto px-2 mb-6 sm:flex sm:items-center">
              <Cog></Cog>
              <h2 className="px-3 sm:px-3 font-mont text-2xl font-roboto leading-6 text-white uppercase">
                CONTACTOS
              </h2>
            </div>
          </div>
          <h3 className="px-9 font-roboto font-bold font-xl text-menuRedOne mb-6">Precisa de formação?</h3>
          <div className="flex items-center gap-x-3 px-2">
            <Email2></Email2>
            <span className='text-white font-roboto'>geral@innovarisk.pt</span>
          </div>
          <div className="flex items-center gap-x-3 px-2">
            <Phone2></Phone2>
            <span className='text-white font-roboto'>+351 215 918 370 | +351 215 918 379</span>
          </div>
        </div>
      </Card>

      {/* <Card extraClasses="p-5 !h-full !w-full">
        <div className="flex flex-col w-fit">
          <div className="flex">
            <div className="w-64 h-32">
              <HorizontalBar data={sideBarData} options={sideBarOptions}></HorizontalBar>
            </div>
            <div className="w-64 h-32">
              <Pie data={sideBarData}></Pie>
            </div>
            <div className="w-64 h-32">
              <Doughnut data={sideBarData}></Doughnut>
            </div>
          </div>
          <div>
            <Table newLook={true}></Table>
          </div>
        </div>
      </Card> */}

      {modalVisibility && (
        <Modal onClose={closeModal}>
          <h2 className="text-black font-mont font-medium text-xxxl pb-8">
            Complete o seu perfil
          </h2>
          <p className="text-black font-lato">
            Estes dados são necessarios para que possa receber os certificados
            de participação nos webinares Innovarisk.
          </p>
          <div className="flex justify-end">
            <FilledButton url="/profile" label="Editar perfil" />
          </div>
        </Modal>
      )}
    </div>
  )
}

export default DashboardBroker
