import { useContext, useState } from 'react'
import { LocaleType, locale } from 'locale'
import { useFormik } from 'formik'

import {
  FilledButton,
  FormInput,
  FormSelect,
  Loader,
  NotificationContainer,
  Wrapper,
  useNotification,
} from 'components'

import TransparentButton from 'components/Buttons/transparent'

import { useMutation, useQuery } from '@tanstack/react-query'

import {
  getPolicies,
  getPolicyAddress,
  updatePolicyAddress,
} from 'services/policies'
import { formatCurrency } from 'utils/currency'
import {
  ADDRESSFIELDS,
  INSTALLMENTS_OPTIONS,
  PAYMENT_OPTIONS,
  validationSchema,
} from './helpers/helper-broker-policy'
import { PolicyAddress } from 'types/policies'
import { AuthContext } from 'contexts/AuthContext'
import { useNavigate, useParams } from 'react-router-dom'
import { Card } from 'components/Card'
import { Cog } from 'components/Icons/cog'

type BrokerPoliciesFormProps = {
  ADDRESS1: string
  ADDRESS2: string
  ADDRESS3: string
  ADDRESS4: string
  POSTCODE: string
  installment_id: number
  payment_id: number
}

type RenderButtonsProps = {
  type: 'address' | 'payment'
  title: string
  condition: boolean
  options?: { value: number; label: string }[]
  callback: (arg: boolean) => void
}

export const PolicyBroker = () => {
  const { policyNo } = useParams() as unknown as { policyNo: number }
  const navigate = useNavigate()
  const { notifications, notify } = useNotification()
  const { currentUser } = useContext(AuthContext)

  /*  const [multipleAddresses, setMultipleAddresses] = useState('')
  const [event, setEvent] = useState(false) */

  const [changeAddress, setChangeAddress] = useState(false)
  const [changeInstallment, setChangeInstallment] = useState(false)
  const [changePayment, setChangePayment] = useState(false)

  const brokerNo = currentUser?.broker?.broker_no
  const contactNo = currentUser?.contact_no

  const updatePolicyAddressMutation = useMutation({
    mutationKey: ['updatePolicyAddress'],
    mutationFn: ({
      id,
      data,
    }: {
      id: number
      data: { brokerNo: string; address: PolicyAddress[] }
    }) =>
      updatePolicyAddress({
        policyNo: id,
        address: data.address,
        brokerNo: data.brokerNo,
      }),
    onError: () => notify('Ocorreu um erro', 'error'),
    onSettled: () =>
      notify('O endereço foi actualizado correctamente', 'success'),
  })

  const { data, isLoading, error } = useQuery({
    queryKey: ['getPolicy', { policyNo }],
    enabled: !!brokerNo && !!policyNo,
    queryFn: () => getPolicies({ params: { brokerNo, contactNo, policyNo } }),
  })

  const {
    data: policyAddress,
    isLoading: loadingPolicyAddress,
    error: policyAddressError,
  } = useQuery({
    queryKey: ['getPolicyAddress', { policyNo }],
    enabled: !!policyNo,
    queryFn: () => getPolicyAddress({ policyNo }),
  })

  const getAddressInitialValues = (
    addressArray: PolicyAddress[],
  ): {
    ADDRESS1: string
    ADDRESS2: string
    ADDRESS3: string
    ADDRESS4: string
    POSTCODE: string
  } => {
    const fields = ADDRESSFIELDS.reduce((acc, field) => {
      const address = addressArray?.find(
        (item: { Question: string }) => item.Question === field,
      )
      return {
        ...acc,
        [field]: address?.Answer || '',
      }
    }, {}) as BrokerPoliciesFormProps

    return fields
  }

  const formik = useFormik<BrokerPoliciesFormProps>({
    initialValues: {
      ...getAddressInitialValues(policyAddress!),
      payment_id: 0,
      installment_id: 0,
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: values => {
      const adressValues = {
        ADDRESS1: values.ADDRESS1,
        ADDRESS2: values.ADDRESS2,
        ADDRESS3: values.ADDRESS3,
        ADDRESS4: values.ADDRESS4,
        POSTCODE: values.POSTCODE,
      }
      const address: PolicyAddress[] = Object.keys(adressValues).map(item => ({
        Question: item,
        Answer: values[item as keyof BrokerPoliciesFormProps] as string,
        InputId: item,
      }))

      updatePolicyAddressMutation.mutate({
        id: policyNo,
        data: { address, brokerNo: brokerNo?.toString()! },
      })
    },
  })

  if (error || policyAddressError) {
    navigate('/404')
  }

  if (isLoading || loadingPolicyAddress) {
    return <Loader />
  }

  const renderDocumentsDownload = () => (
    <Card extraClasses="mb-10 bg-lightGrey p-6 w-full">
      <div className="sm:flex-auto px-2 pt-8 mb-6 sm:flex sm:items-center">
        <Cog></Cog>
        <h1 className="px-3 sm:px-3 text-2xl font-roboto leading-6 text-black uppercase">
          Documentação da Apólice
        </h1>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-2 justify-items-center items-stretch">
        {[
          'Aviso de Recibo',
          'Factura Recibo',
          'Condições Gerais',
          'Particulares',
        ].map((item, index) => {
          return (
            <FilledButton
              key={item.concat(index.toString())}
              className="w-[200px]"
              label={item}
            />
          )
        })}
      </div>
    </Card>
  )

  const buttonsData: RenderButtonsProps[] = [
    {
      type: 'address',
      title: 'Alterar Dados Pessoais e de Pagamento',
      condition: changeAddress,
      callback: setChangeAddress,
    },
    {
      type: 'payment',
      title: 'Alterar fraccionamento',
      condition: changeInstallment,
      options: INSTALLMENTS_OPTIONS,
      callback: setChangeInstallment,
    },
    {
      type: 'payment',
      title: 'Alterar forma de pagamento',
      condition: changePayment,
      options: PAYMENT_OPTIONS,
      callback: setChangePayment,
    },
  ]

  const renderButtons = ({
    type,
    title,
    condition,
    options,
    callback,
  }: RenderButtonsProps) => (
    <Card key={title} extraClasses="bg-lightGrey p-6 mb-10 w-full">
      <div className="sm:flex-auto px-2 pt-8 mb-6 sm:flex sm:items-center">
        <Cog></Cog>
        <h1 className="px-3 sm:px-3 text-2xl font-roboto leading-6 text-black uppercase">
          {title}
        </h1>
      </div>
      {type === 'address' ? (
        <div>
          {Object.keys(formik.values).map(item => {
            if (!ADDRESSFIELDS.includes(item)) return
            return (
              <div key={`container-${item}`} className="px-9">
                <FormInput
                  extraClasses={`${condition ? 'block' : 'hidden'}`}
                  label={locale[item as LocaleType] as string}
                  key={`key${item}`}
                  formik={formik}
                  name={item}
                  type="text"
                />
              </div>
            )
          })}
        </div>
      ) : (
        <FormSelect
          extraClasses={`${condition ? 'block' : 'hidden'}`}
          label="Alterar fraccionamento"
          allowEmpty={false}
          formik={formik}
          name="installment_id"
          options={options}
        />
      )}
      <div
        className={`flex justify-between items-center ${condition ? 'block' : 'hidden'
          }`}
      >
        <FilledButton type="submit" label="Gravar Alterações" />
        <TransparentButton
          type="button"
          className="!text-brand border-brand !rounded h-fit !py-2 !text-sm font-medium"
          label="Cancelar"
          action={() => callback(false)}
        />
      </div>
      <FilledButton
        className={`${condition ? 'hidden' : 'block'}`}
        label="Alterar"
        action={() => callback(true)}
      />
    </Card>
  )

  const renderPolicyData = () => {
    const policy = data[0]
    const policyDetails = Object.keys(policy).map(key => {
      // we don't want to show the LastEvent key
      if (key === 'LastEvent') return

      const premiumKeys = ['Prémio Anual', 'Capital Seguro']
      const value = premiumKeys.includes(key)
        ? formatCurrency(policy[key])
        : policy[key]

      return {
        label: key,
        value,
      }
    })
    return (
      <table className="border-collapse w-full border-none">
        <tbody>
          {policyDetails
            //need to filter out the undefined values (LastEvent key)
            .filter(Boolean)
            .map(({ label, value }: any, index: number) => (
              <tr key={`row${index}`} className="cursor-default h-8 even:bg-lightGrey">
                <td className="text-menuRedOne font-roboto text-sm pl-4 rounded-md uppercase">{label}</td>
                <td className="text-end text-black font-roboto text-sm font-medium pr-4 rounded-md">
                  {value}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    )
  }

  return (
    <div className="w-full flex-1 py-12 overflow-auto">
      <Wrapper extraClasses="grid sm:grid-cols-2 gap-4">
        <NotificationContainer notifications={notifications} />
        <Card extraClasses="p-5 order-1 row-span-full">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto px-2 pt-8 mb-6 sm:flex sm:items-center">
              <Cog></Cog>
              <h1 className="px-3 sm:px-3 text-2xl font-roboto leading-6 text-black uppercase">
                Apólice
              </h1>
            </div>
          </div>
          <div className="md:pr-8">{renderPolicyData()}</div>
        </Card>
        <div className="md:pl-8">
          <form onSubmit={formik.handleSubmit}>
            {renderDocumentsDownload()}
            {buttonsData.map(renderButtons)}
          </form>
        </div>
      </Wrapper>
    </div>
  )
}
