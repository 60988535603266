import { useState } from 'react'
import { locale } from 'locale'
import { formatDate } from 'utils/dates'
import {
  FilledButton,
  Loader,
  Modal,
  useNotification,
  Table,
  Video,
  Wrapper,
  NotificationContainer,
} from 'components'
import { useTrainings } from 'hooks/useTrainings'
import { Card } from 'components/Card'

export const Tutorials = () => {
  const { notifications } = useNotification()
  const [showModal, setShowModal] = useState(false)
  const [videoId, setVideoId] = useState(null)
  const [videoTitle, setVideoTitle] = useState(null)

  const { trainings: tutorials, loading } = useTrainings({
    params: {
      status: 'published',
      tutorial: true,
      type: 'tutorials',
    },
  })

  if (loading) {
    return <Loader />
  }
  return (
    <div className="w-full flex-1 py-12">
      {showModal && videoId && (
        <Modal
          classes="!w-auto"
          onClose={() => {
            setShowModal(false)
            setVideoId(null)
          }}
        >
          <h2 className="pb-8 font-mont text-3xl text-brand font-medium">
            Tutorial - {videoTitle}
          </h2>
          <Video videoId={videoId} classes="w-full h-[415px] pb-8" />
          <div className="flex justify-end">
            <FilledButton
              label={locale.close}
              action={() => {
                setShowModal(false)
                setVideoId(null)
              }}
            />
          </div>
        </Modal>
      )}
      <Wrapper>
        <NotificationContainer notifications={notifications} />
        <h2 className="uppercase text-sm font-medium text-darkBlue">
          Formação
        </h2>
        <h1 className="pb-10 text-xxxl font-medium text-brand">Tutoriais</h1>
        <Card>
          <Table
          newLook={true}
            title="Mapa de tutoriais"
            width="w-full"
            columns={[
              {
                label: locale.title,
                property: 'name',
                sortable: false,
                format: item => item.name,
              },
              {
                label: locale.date,
                property: 'date',
                sortable: false,
                format: item => formatDate(item.date).dashed,
              },
              {
                label: 'Vídeo',
                property: 'conference_video',
                sortable: false,
                format: item =>
                  item.conference_video ? (
                    <button
                      onClick={() => {
                        setShowModal(true)
                        setVideoId(item.conference_video)
                        setVideoTitle(item.name)
                      }}
                    >
                      <img
                        src={`http://img.youtube.com/vi/${item.conference_video}/sddefault.jpg`}
                        width="100px"
                        alt={item.name}
                      />
                    </button>
                  ) : (
                    <p>Não disponível</p>
                  ),
              },
            ]}
            data={tutorials}
          />
        </Card>
      </Wrapper>
    </div>
  )
}
