import { locale } from 'locale'
import moment from 'moment'

import {
  Loader,
  Wrapper,
  Table,
  useNotification,
  NotificationContainer,
} from 'components'

import { formatDate } from 'utils/dates'
import { Training, TrainingCategory } from 'types/training'
import { useMutation } from '@tanstack/react-query'
import { insertPresence } from 'services/trainings'

import { useTrainings } from 'hooks/useTrainings'
import { useContext, useEffect } from 'react'
import { AuthContext } from 'contexts/AuthContext'
import { Link } from 'react-router-dom'
import { Card } from 'components/Card'
import { Cog } from 'components/Icons/cog'

export const Trainings = () => {
  const { notifications, notify } = useNotification()

  const { currentUser } = useContext(AuthContext)

  const today = moment().format('YYYY-MM-DD')
  const currentHour = moment().subtract(2, 'hour').format('HH:mm')

  const {
    trainings,
    loading,
    formatTrainingCategories,
    errorFetchingTrainings,
  } = useTrainings({
    params: {
      status: 'published',
      tutorial: false,
      date: today,
      hour: currentHour,
      type: 'present',
    },
  })

  useEffect(() => {
    if (errorFetchingTrainings) {
      notify('Erro ao carregar formações', 'error')
    }
  }, [errorFetchingTrainings])

  const { mutateAsync: createRegistration } = useMutation({
    mutationKey: ['insertPresence'],
    mutationFn: async (item: Training) => {
      if (!currentUser)
        return notify('É necessário estar logado para se inscrever', 'error')
      await insertPresence({
        training_id: item.id,
        user_id: currentUser.id,
      })
    },
  })

  const { mutateAsync: updateRegistration } = useMutation({
    mutationKey: ['insertPresence'],
    mutationFn: async (item: Training) => {
      if (!currentUser)
        return notify('É necessário estar logado para se inscrever', 'error')
      /*  await updatePresence({
        training_id: item.id,
        user_id: currentUser.id,
      }) */
    },
  })

  if (loading) {
    return <Loader />
  }

  return (
    <div className="w-full flex-1 py-12">
      <Wrapper>
        <NotificationContainer notifications={notifications} />
        <Card extraClasses="p-8">
          <div className="sm:flex-auto pt-8 mb-6 sm:flex sm:items-center">
            <Cog></Cog>
            <h1 className="px-3 sm:px-3 font-roboto text-xl font-medium leading-6 text-black uppercase">
              Formação
            </h1>
          </div>
          <h1 className="pb-10 text-xl font-medium text-brand">
            Sessões de Formação
          </h1>
          <div className="grid grid-cols-2 gap-x-8 items-start justify-center text-base leading-7 text-balck">
            <div>
              <p className="">
                Sabemos que os nossos seguros são especializados e, muitas vezes,
                soluções diferentes daquelas que habitualmente gere no seu
                dia-a-dia. Por isso compreendemos que por vezes é complicado ter
                um conhecimento aprofundado de todos os produtos disponibilizados
                pelo mercado segurador.
              </p>
              <p>
                Sendo que um dos nossos pilares principais é proporcionar aos
                nossos parceiros ferramentas e apoio para o seu negócio, queremos
                facilitar o seu trabalho, convidando-o a participar nas nossas
                formações ao longo deste ano.
              </p>
            </div>
            <div>
              <h3 className="pb-2 text-xl font-medium leading-none text-darkBlue">
                Inscrever numa formação e|GO
              </h3>
              <p>
                Caso pretenda frequentar uma destas formações, consulte o mapa
                abaixo e carrege no botão Inscrever, ou contacte o gestor do
                Innova|GO para mais informações:
              </p>
              <Link
                className="text-sm text-brand font-medium underline"
                target="_blank"
                rel="noopener noreferrer"
                to="mailto:innovago@innovarisk.pt"
              >
                innovago@innovarisk.pt
              </Link>
            </div>
          </div>
        </Card>
        <Card>
          <Table
            newLook={true}
            title="Mapa de formações 2024"
            columns={[
              {
                label: locale.title,
                property: 'name',
                sortable: false,
                format: item => item.name,
              },
              {
                label: locale.teacher,
                property: 'teacher',
                sortable: false,
                format: item => item.teacher,
              },
              {
                label: locale.date,
                property: 'date',
                sortable: false,
                format: item => formatDate(item.date).dashed,
              },
              {
                label: locale.hour,
                property: 'hour',
                sortable: false,
                format: item => item.hour,
              },
              {
                label: locale.place,
                property: 'place',
                sortable: false,
                format: item => item.city,
              },
              {
                label: locale.duration,
                property: 'duration',
                sortable: false,
                format: item => item.duration + ' minutos',
              },
              {
                label: 'Categorias',
                property: 'training_categories',
                sortable: false,
                format: item =>
                  formatTrainingCategories(
                    item?.training_category.map(
                      (e: TrainingCategory) => e.category_id,
                    ),
                  ),
              },
              {
                label: locale.registration,
                property: 'link',
                sortable: false,
                format: item => {
                  return item.presence && item.presence.length === 0 ? (
                    <button
                      className="underline underline-offset-4 decoration-cloudyBlue hover:decoration-brand"
                      onClick={() => createRegistration(item)}
                    >
                      Inscrever
                    </button>
                  ) : (
                    <Link
                      onClick={() => updateRegistration(item)}
                      className="underline underline-offset-4 decoration-cloudyBlue hover:decoration-brand"
                      to={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ir para a conferência
                    </Link>
                  )
                },
              },
            ]}
            data={trainings}
          />
        </Card>
      </Wrapper>
    </div>
  )
}
