export const Email = () => (
  <svg
    className='contacts-icon'
    aria-hidden='true'
    data-prefix='fas'
    data-icon='expand'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 448 512'
  >
    <path
      fill='#ffffff'
      d='M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z'
    ></path>
  </svg>
);

export const Email2 = () => (
  <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.7 0.360107H1.12C0.519995 0.360107 0.039978 0.840124 0.039978 1.44012V8.92014C0.039978 9.52014 0.529995 10.0001 1.12 10.0001H13.7C14.3 10.0001 14.78 9.52014 14.78 8.92014V1.44012C14.78 0.840124 14.3 0.360107 13.7 0.360107ZM13.91 1.61011L7.54999 5.13013C7.50999 5.15013 7.46999 5.16013 7.42999 5.16013H7.40002C7.36002 5.16013 7.32003 5.15013 7.28003 5.13013L0.919983 1.61011C0.799983 1.54011 0.760007 1.39011 0.820007 1.27011C0.890007 1.15011 1.03997 1.11014 1.15997 1.17014L7.40997 4.63013L13.66 1.17014C13.78 1.10014 13.93 1.15011 14 1.27011C14.08 1.39011 14.03 1.54011 13.91 1.61011Z" fill="url(#paint0_linear_125_3434)" />
    <defs>
      <linearGradient id="paint0_linear_125_3434" x1="0.040978" y1="5.18021" x2="14.7847" y2="5.18021" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EA3828" />
        <stop offset="1" stopColor="#AF2026" />
      </linearGradient>
    </defs>
  </svg>
);