import { Arrow } from 'components/Icons'
import { Cog } from 'components/Icons/cog'
import { useState } from 'react'
import { Training, Tutorial } from 'types/training'

type ColumnType = {
  label: string
  property: string
  sortable: boolean
  format: (item: any) => React.ReactNode
}

type DataType = Training[] | Tutorial[]

type TablePropsType = {
  title?: string
  description?: string
  columns: ColumnType[]
  width?: string
  data: DataType
  newLook?: boolean
}
export const Table = ({
  title,
  description,
  columns,
  width,
  data,
  newLook,
}: TablePropsType) => {
  const [sortConfig, setSortConfig] = useState({
    col: '',
    direction: '',
  })

  const handleHeaderClick = (label: string) => {
    if (sortConfig.col === label) {
      if (sortConfig.direction === 'asc') {
        setSortConfig({ col: label, direction: 'desc' })
      } else {
        setSortConfig({ col: '', direction: '' })
      }
    } else {
      setSortConfig({ col: label, direction: 'asc' })
    }
  }
  const sortedData = (() => {
    if (!sortConfig.col) {
      return data
    }

    return [...data].sort((a: any, b: any) => {
      const aValue = a[sortConfig.col]
      const bValue = b[sortConfig.col]

      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1
      } else if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1
      } else {
        return 0
      }
    })
  })()

  if (newLook) {
    return (
      <div className="max-h-[528px] overflow-x overflow-y-auto lg:overflow-x-hidden">
        <div className="sm:flex sm:items-center">
          {title && (
            <div className="sm:flex-auto px-9 pt-8 mb-6 sm:flex sm:items-center">
              <Cog></Cog>
              <h1 className="px-3 sm:px-3 font-mont text-xl font-medium leading-6 text-black uppercase">
                {title}
              </h1>
              {description && (
                <p className="mt-2 text-sm text-gray-700">{description}</p>
              )}
            </div>
          )}
        </div>
        <div className="flow-root">
          <div className="w-full">
            <div className="inline-block min-w-full align-middle">
              <table
                className={`p-9 min-w-full border-separate border-spacing-0 table-fixed font-roboto text-sm ${width}`}
              >
                <thead>
                  <tr className="">
                    {columns.map((header, index) => {
                      return (
                        <th
                          key={`header${index}`}
                          scope="col"
                          className="uppercase border-b border-black text-left pb-2.5 px-4"
                        >
                          {header.sortable ? (
                            <button
                              onClick={() => handleHeaderClick(header.property)}
                              className="group inline-flex items-center uppercase tracking-wide "
                            >
                              <p className="text-left">{header.label}</p>

                              {sortConfig.col === header.property ? (
                                <span
                                  className={`invisible ml-2 flex-none rounded text-cloudyBlue group-hover:visible group-focus:visible ${(sortConfig.direction === 'asc' ||
                                    sortConfig.direction === 'desc') &&
                                    header.property === sortConfig.col
                                    ? 'bg-lightGrey rounded-sm !text-brand !visible'
                                    : ''
                                    }`}
                                >
                                  {sortConfig.direction === 'asc' ? (
                                    <Arrow
                                      className="h-4 w-4 rotate-180"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <Arrow
                                      className="h-4 w-4"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              ) : (
                                <span
                                  className={`invisible ml-2 flex-none rounded text-cloudyBlue group-hover:visible group-focus:visible`}
                                >
                                  <Arrow className="h-4 w-4" aria-hidden="true" />
                                </span>
                              )}
                            </button>
                          ) : (
                            <span>{header.label}</span>
                          )}
                        </th>

                      )
                    })}

                  </tr>
                </thead>
                <tbody>
                  {sortedData?.length > 0 ? (
                    sortedData.map((row: any, rowIndex: number) => (
                      <tr
                        key={rowIndex}
                        className={`even:bg-lightGrey`}
                      >
                        {columns.map((column, colIndex) => (
                          <td
                            key={`col${colIndex}_${column.label}`}
                            className="border-b px-3 sm:px-4 border-gray-200 whitespace-nowrap py-4 text-sm font-normal text-black text-wrap"
                          >
                            {column.format(row)}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr
                      key={'empty_row'}
                      className=""
                    >
                      <td
                        colSpan={columns.length}
                        align="center"
                        className="text-grey font-lato text-sm py-4 uppercase tracking-widest leading-10"
                      >
                        Não existem dados
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>)
  }

  return (
    <div className="rounded-xl border-[1px] border-cloudyBlue max-h-[528px] overflow-x overflow-y-auto lg:overflow-x-hidden">
      <div className="sm:flex sm:items-center">
        {title && (
          <div className="sm:flex-auto pt-8 mb-8">
            <h1 className="px-4 sm:px-6 font-mont text-xl font-medium leading-6 text-darkBlue">
              {title}
            </h1>
            {description && (
              <p className="mt-2 text-sm text-gray-700">{description}</p>
            )}
          </div>
        )}
      </div>
      <div className="flow-root">
        <div>
          <div className="inline-block min-w-full align-middle">
            <table
              className={`min-w-full border-separate border-spacing-0 table-fixed font-lato ${width}`}
            >
              <thead>
                <tr className="divide-x-4">
                  {columns.map((header, index) => {
                    return (
                      <th
                        key={`header${index}`}
                        scope="col"
                        className={` ${sortConfig.col === header.label
                          ? 'bg-blueyGrey'
                          : 'bg-dirtyBlue'
                          }  sticky self-start h-auto top-0 border-b  px-3 sm:px-4 bg- py-3.5 text-left font-semibold uppercase text-sm text-lightGrey tracking-wide backdrop-blur backdrop-filter  `}
                      >
                        {header.sortable ? (
                          <button
                            onClick={() => handleHeaderClick(header.property)}
                            className="group inline-flex items-center uppercase tracking-wide"
                          >
                            <p className="text-left">{header.label}</p>

                            {sortConfig.col === header.property ? (
                              <span
                                className={`invisible ml-2 flex-none rounded text-cloudyBlue group-hover:visible group-focus:visible ${(sortConfig.direction === 'asc' ||
                                  sortConfig.direction === 'desc') &&
                                  header.property === sortConfig.col
                                  ? 'bg-lightGrey rounded-sm !text-brand !visible'
                                  : ''
                                  }`}
                              >
                                {sortConfig.direction === 'asc' ? (
                                  <Arrow
                                    className="h-4 w-4 rotate-180"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <Arrow
                                    className="h-4 w-4"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            ) : (
                              <span
                                className={`invisible ml-2 flex-none rounded text-cloudyBlue group-hover:visible group-focus:visible`}
                              >
                                <Arrow className="h-4 w-4" aria-hidden="true" />
                              </span>
                            )}
                          </button>
                        ) : (
                          <span>{header.label}</span>
                        )}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {sortedData?.length > 0 ? (
                  sortedData.map((row: any, rowIndex: number) => (
                    <tr
                      key={rowIndex}
                      className={`hover:bg-silver ${rowIndex % 2 === 1 ? 'bg-lightGrey' : ''
                        }`}
                    >
                      {columns.map((column, colIndex) => (
                        <td
                          key={`col${colIndex}_${column.label}`}
                          className="border-b px-3 sm:px-4 border-gray-200 whitespace-nowrap py-4 text-sm font-normal text-black text-wrap"
                        >
                          {column.format(row)}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr
                    key={'empty_row'}
                    className="hover:bg-silver bg-lightGrey"
                  >
                    <td
                      colSpan={columns.length}
                      align="center"
                      className="text-grey font-lato text-sm py-4 uppercase tracking-widest leading-10"
                    >
                      Não existem dados
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
