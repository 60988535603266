import { Logo2 } from 'components/Icons/logo'

export const HeaderPublic = () => {
  return (
    <header className="h-28 divide-y-8  divide-solid divide-brand border-b-8 border-brand">
        <div className="ml-28 mt-4 mb-4 h-20 w-60">
          <Logo2 />
        </div>
    </header>
  )
}