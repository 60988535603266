import { Download } from 'components/Icons'
import { List } from 'components'
import { BodyItem, ListFileItemType } from 'types/editor'
import { Accordion } from 'components'
import { ClausesForm } from 'components/Form/clausesForm'
import { Link } from 'react-router-dom'

export const ContentEditor = ({
  attachments,
  item,
  index,
  simulator,
  notify,
}: {
  attachments?: {
    url: string
    file_name: string
  }[]
  item: BodyItem
  index: number
  simulator?: boolean
  notify?: any
}) => {
  switch (item.type) {
    case 'header':
      const HeadingTag = `h${item.data.level}` as keyof JSX.IntrinsicElements
      return (
        <HeadingTag
          key={`product${index}`}
          className={` font-roboto font-medium text-menuRedOne uppercase ${
            simulator ? 'text-lg pb-2' : 'text-xl pb-4'
          }`}
        >
          {item.data.text}
        </HeadingTag>
      )
    case 'delimiter':
      return <div className="w-[1px] h-full bg-grey" />
    case 'list':
      return (
        <List
          id={`list${index}`}
          type={item.data.style}
          items={item.data.items as string[]}
        />
      )
    case 'fileList':
      return (
        <div className="w-full">
          <p className="font-mont text-base font-normal text-darkBlue leading-7 tracking-[2.25px]">
            {item.data.title}
          </p>
          {item.data.items &&
            item.data.items.map((item, index) => {
              const attachment = attachments
                ? attachments.filter(
                    attach =>
                      attach.file_name ===
                      (item as ListFileItemType).name
                        .replace('amp;', '')
                        .replace('nbsp;', ' '),
                  )
                : []
              if (attachment[0]) {
                return (
                  <Link
                    className="inline-block w-full p-2 uppercase font-mont text-sm font-medium text-brand leading-7"
                    to={attachment[0].url}
                    target="_blank"
                    rel="noreferrer"
                    key={`download_${index}`}
                  >
                    <div className="flex items-center">
                      <span className="w-4">
                        <Download />
                      </span>
                      <p className="px-4">{(item as ListFileItemType).label}</p>
                    </div>
                  </Link>
                )
              } else {
                return false
              }
            })}
        </div>
      )

    case 'formPlaceholder':
      return <ClausesForm notify={notify} />
    case 'accordion':
      return <Accordion data={item.data} />

    default:
    case 'paragraph':
      return (
        <p className="pb-4 font-lato text-base font-normal text-black leading-7">
          {item.data.text.replace(/&nbsp;/g, ' ')}
        </p>
      )
  }
}
