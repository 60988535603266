import {
  FilledButton,
  Wrapper,
  NotificationContainer,
  useNotification,
} from 'components'
import { locale } from 'locale'

import { useMutation } from '@tanstack/react-query'
import { insertPresence } from 'services/trainings'

// const TrainingBgImage = styled(BgImage)`
//   @media screen and (max-width: ${theme.breakpoints.medium}) {
//     background-position: center top;
//     background-size: auto;
//     padding: 20% 0 0;
//   }
// `;

export const Trainings = ({
  content,
  image,
  userID,
  newLook,
}: {
  content: any
  image: any
  userID: number
  newLook: boolean
}) => {
  const { id, name, city, hour, date, presence, link } = content

  const { notifications, notify } = useNotification()

  const { mutateAsync: handleRegistration } = useMutation({
    mutationKey: ['insertPresence'],

    mutationFn: ({
      training_id,
      user_id,
    }: {
      training_id: number
      user_id: number
    }) => insertPresence({ training_id, user_id }),
  })

  /*   const [insertPresence] = useMutation(insertPresenceMutation, {
    onCompleted: () => {
      notify('O registo foi feito com sucesso', 'success')
    },
    onError: error => {
      console.log(error)
      notify()
    },
    refetchQueries: ['getTrainings'],
  }) */

  return (
    <div className="pt-14 pb-14 bg-cloudyBlue2/20">
      <NotificationContainer notifications={notifications} />
      <Wrapper>
        <div
          className={`bg-right-top bg-no-repeat h-auto`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            <div className="pt-14">
              <h3 className="font-mont text-darkBlue pb-1 text-base uppercase leading-[0.5]">
                Formação
              </h3>
              <h2 className="font-mont text-brand font-medium pb-6 text-xxxl">
                {name}
              </h2>
              <p className="font-lato pb-4">
                Sabemos que os nossos seguros são especializados e, muitas
                vezes, soluções diferentes daquelas que habitualmente gere no
                seu dia-a-dia. Por isso compreendemos que por vezes é complicado
                ter um conhecimento aprofundado de todos os produtos
                disponibilizados pelo mercado segurador.
              </p>
              <p className="font-lato pb-4">
                Sendo que um dos nossos pilares principais é proporcionar aos
                nossos parceiros ferramentas e apoio para o seu negócio,
                queremos facilitar o seu trabalho, convidando-o a participar nas
                nossas formações ao longo deste ano.
              </p>
              <FilledButton
                className="px-8 text-sm"
                url="/trainings"
                label={locale.seeAllTrainings}
              />
            </div>
            <div className="pt-40">
              <div className="bg-cloudyBlue2">
                <p className="font-lato text-center leading-[44px]">
                  {locale.openRegistrations}
                </p>
              </div>
              <div className="border-b-2 border-cloudyBlue px-2">
                <div className="grid grid-cols-2 h-[44px] items-center">
                  <span className="font-lato text-sm tracking-widest uppercase text-grey">
                    {locale.training.singular}
                  </span>
                  <span className="font-lato text-sm">{name}</span>
                </div>
              </div>
              <div className="border-b-2 border-cloudyBlue px-2">
                <div className="grid grid-cols-2 h-[44px] items-center">
                  <span className="font-lato text-sm tracking-widest uppercase text-grey">
                    {locale.date}
                  </span>
                  <span className="font-lato text-sm">{date}</span>
                </div>
              </div>
              <div className="border-b-2 border-cloudyBlue px-2">
                <div className="grid grid-cols-2 h-[44px] items-center">
                  <span className="font-lato text-sm tracking-widest uppercase text-grey">
                    {locale.timeSchedule}
                  </span>
                  <span className="font-lato text-sm">{hour}</span>
                </div>
              </div>
              <div className="border-b-2 border-cloudyBlue px-2">
                <div className="grid grid-cols-2 h-[44px] items-center">
                  <span className="font-lato text-sm tracking-widest uppercase text-grey">
                    {locale.place}
                  </span>
                  <span className="font-lato text-sm">{city}</span>
                </div>
              </div>
              <div className="flex justify-end pt-8">
                {presence.length === 0 ? (
                  <FilledButton
                    className="text-sm"
                    label="INSCREVER"
                    onClick={() => handleRegistration(id)}
                  />
                ) : (
                  link && (
                    <FilledButton
                      url={link}
                      label="Ir para a conferência"
                      target="_blank"
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  )
}
