import { routes } from 'routes'
import { Header } from 'components'

import { AppProvider } from 'contexts/AppContext'
import { AuthProvider } from './contexts/AuthContext'

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'

export const queryClient = new QueryClient()
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <AuthProvider>
          <Router>
            <div className="h-screen flex flex-col bg-cover bg-backgroundImage">
              <Header />
              <Routes>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                    ErrorBoundary={route.errorBoundary}
                    hasErrorBoundary={route.errorBoundary ? true : false}
                  />
                ))}
              </Routes>
            </div>
          </Router>
        </AuthProvider>
      </AppProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
