export const Phone = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
    <path
      fill='#ffffff'
      d='M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z'
    ></path>
  </svg>
);

export const Phone2 = () => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.72 9.99012C14.63 9.84012 13.99 9.54014 13.99 9.54014C13.74 9.42014 11.88 8.51015 11.54 8.39015C11.24 8.28015 10.9 8.16012 10.6 8.61012C10.37 8.95012 9.69998 9.73014 9.47998 9.98014C9.32998 10.1501 9.19003 10.2101 8.84003 10.0401C8.78003 10.0101 7.12999 9.31013 5.98999 8.31013C4.97999 7.42013 4.28003 6.33015 4.03003 5.89015C3.87003 5.63015 3.97998 5.51013 4.16998 5.31013C4.28998 5.19013 4.80998 4.57013 4.84998 4.50013C4.92998 4.37013 5.08002 4.07014 5.08002 4.07014C5.23002 3.77014 5.13999 3.52014 5.04999 3.35014C4.98999 3.23014 4.04001 0.960123 3.95001 0.740123C3.68001 0.100123 3.38999 0.0601196 3.10999 0.0801196C2.97999 0.0901196 1.72001 0.100132 1.32001 0.530132L1.25 0.600139C0.86 1.01014 0.039978 1.88014 0.039978 3.54014C0.039978 3.92014 0.0999805 4.33012 0.22998 4.77012C0.45998 5.57012 0.92002 6.45014 1.52002 7.26014C1.53002 7.28014 2.44997 8.55012 2.90997 9.08012C4.35997 10.7501 6.00998 11.9801 7.66998 12.6201C9.78998 13.4401 10.69 13.6301 11.21 13.6301C11.43 13.6301 11.91 13.5201 12.01 13.5101C12.66 13.4501 14.2 12.6501 14.52 11.7601C14.82 10.9201 14.83 10.1801 14.72 9.99012Z" fill="url(#paint0_linear_125_3435)" />
    <defs>
      <linearGradient id="paint0_linear_125_3435" x1="0.040978" y1="6.86013" x2="14.7847" y2="6.86013" gradientUnits="userSpaceOnUse">
        <stop stopColor="#EA3828" />
        <stop offset="1" stopColor="#AF2026" />
      </linearGradient>
    </defs>
  </svg>
);