import { useContext } from 'react'

import { AuthContext } from '../../contexts/AuthContext'
import { ProductBroker } from './productBroker'
import ProductClient from './productClient'
import { useParams } from 'react-router-dom'
import { Loader } from 'components'

export const Product = () => {
  const { slug } = useParams() as { slug: string }

  const { currentUser } = useContext(AuthContext)

  if (!currentUser) return <Loader />

  return (
    <>
      {currentUser?.role ? (
        <ProductBroker newLook={true} slug={slug} />
      ) : (
        <ProductClient slug={slug} />
      )}
    </>
  )
}
